<template>
    <div class="pad-row mar-t">
        <div class="whiteBg b1 pad">
            <p class="fc blue right">刷新</p>
            <div class="flex">
                <img :src="imgUrl" class="div6">
            </div>
            <p class="mar-t center gray2">让客户扫描二维码，并充值付款即刻增加推广数量</p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            imgUrl : '/static/img/test/hello.png',
        }
    },
    created(){
        this.$emit('onActive', 0)
        if(this.$ls.get("edCurrentUser").userPromote){
            this.imgUrl = this.$ls.get("edCurrentUser").userPromote.qrCodeUrl;
        }else{
            this.imgUrl='';
        }
    }
}
</script>

<style>

</style>
